<template>
<div>
    <div v-if="selectlan =='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                
                  <ul class="service-ui show-head">

                    <li  @click="$router.push('domesticsector')"  >
                        <span class="dot-li"></span>
                        Domestic Sector
                    </li> 
                     <li @click="$router.push('babycare')">
                        <span class="dot-li"></span>
                       Baby Care
                    </li>
                    <li class="service-ui-active" >
                          <div class="dot-li-active"></div>
                        Moving In/Out
                    </li>
                     <li @click="$router.push('events')" >
                        <span class="dot-li"></span>
                        Special Events Care
                    </li>
                     
                 </ul>
                  <resmenu :name="'Moving In/Out'"/>
                   <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/infectioncontrol/sanitisation&disinfection')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Sanitization & Disinfication
                    </div>
                     <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2"> General Cleaning
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">Moving <span class="w400">in/out</span> </div>
                   <div class="sub-content mt-2">
                  Maintaining residence hygiene when switching tenants doesn’t have to be stressful. We
provide a comprehensive cleaning service between the checkout of previous tenants and
the check-in of new ones. It keeps the house safe from dry soil, dirt, viruses and also assures
the quality of assets and furniture for a longer-lasting life range. BLEACH® does that for you
with an awesome price package that includes 2 visits.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                     Due to the lack of quality cleaning services, many tenants and landlords undergo a great
deal of stress for property cleaning/maintenance, especially at the time of checkout and
check-ins. The landlord ends up losing the long-life value of the installed assets and the
tenants lose their moved assets/furniture as well, which leaves both parties at a loss. What
a predicament!

                 </div>
                  <div class="bold-heading mt-10">
                    WHY IT IS VITAL TO CLEAN & DISINFECT YOUR PROPERTY WHEN AN OLD TENANT LEAVES ?
                 </div>
                 <div class="mt-5 sub-content">
                     Cleaning brings many paybacks for landlords and tenants alike. Landlords benefit by
receiving lower insurance premiums, and tenants benefit by living in a healthier
environment where bacteria and pathogens are not a problem.
                 </div>
             </div>
         </div>
      </div>
  </div>
  
</div>
  <div v-if="selectlan =='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                
                  <ul class="service-ui-ar show-head">

                    <li  @click="$router.push('domesticsector')"  class="arabic-font">
                        <span class="dot-li-ar"></span>
                        قطاع األعمال المنزلية 
                    </li> 
                     <li @click="$router.push('babycare')" class="arabic-font">
                        <span class="dot-li-ar"></span>
                       رعاية األطفال
                    </li>
                    <li class="service-ui-active arabic-font" >
                          <div class="dot-li-ar-active"></div>
                       أعمال انتقال المأجر
                    </li>
                     <li class="arabic-font" @click="$router.push('events')" >
                        <span class="dot-li-ar"></span>
                        رعاية المناسبات الخاصة
                    </li>
                     
                 </ul>
                  <resmenu :name="'عمال انتقال المأجر من والى العين'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font"> أعمال انتقال المأجر</div>
                   <div class="sub-content mt-2 arabic-font">
                  الحفاظ على نظافة محل الإقامة عند تبديل المستأجرين لا يجب أن يكون مرهقًا، فنحن نقدم خدمة تنظيف شاملة بين تسجيل خروج المستأجر السابق وتسجيل وصول المستأجرين الجدد. هذه الخدمة تحافظ على المنزل لكي يظل في مأمن من التربة الجافة، والأوساخ، والفيروسات، كما تضمن هذه الخدمة جودة الأصول والأثاث لدورة حياة أطول، توفر بليتش® كل هذا من أجلك عبر حزمة أسعار رائعة تشمل زيارتين. 

                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                    هل تعلم؟ 
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                     يتعرض الكثير من أصحاب العقار والمستأجرين الى قدر كبير من الضغط على خلفية ضرورة تنظيف الممتلكات أو صيانتها، خاصة عند أوقات المغادرة أو عند تسجيل الوصول بسبب نقص خدمات تنظيف ذات الجودة العالية، فينتهي الأمر بخسارة صاحب العقار للقيمة الطويلة الأجل لأصوله الموضوعة، فيما يفقد المستأجر أصوله ومفروشاته كذلك، ما يترك الطرفين خاسرين، يا لها من معضلة. 

                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                   لماذا تضطر الى تنظيف وتعقيم الممتلكات الخاصة بك عند مغادرة مستأجر سابق؟
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                     للتنظيف الكثير من العوائد على ملاك العقارات كما للمستأجر، فيستفيد مالك العقار من خلال سداد أقساط تأمين أقل، واستفادة المستأجر تأتي من خلال العيش في بيئة صحية، حيث لا تشكل البكتيريا ومسببات الأمراض مشكلة
                 </div>
             </div>
         </div>
      </div>
  </div>
 
</div>

</div>
</template>

<script>
import resmenu from '../../components/menu/resmenu.vue'
export default {
    components:{
        resmenu
    },
 data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
    }
}
</script>

<style>

</style>