<template>
 <div>
  <div>
      <section class=""  >
         <div class="bg-header-img-ic bg-header  ">
         </div>
      </section>
  </div>
    <div v-if="selectlan=='EN'">
   
         <div class="bcontainer">
      <div class="pmy-15">
         <div class="page-head">
                INFECTION  <span class="w400">CONTROL</span>
              </div>
        <div class="sub-content mt-2 text-left">
          BLEACH® Infection Control services include sanitizing and disinfecting facilities such as
commercial and residential spaces. We help reduce the presence of harmful bacteria and
prevent the transmission of infectious diseases, significantly. Our No. 1 priority is the health,
safety, and wellbeing of our customers. We offer two types of infection control services:
Surface Sanitation & Disinfection – This service provides environmentally friendly cleaning
solutions to thoroughly disinfect bacteria and viruses from the air as well as all surfaces. We
use specialized tools to sterilize high-traffic environments like meeting rooms, restrooms,
kitchens, desks, and reception areas, among others.<span v-if="!dc" style="color:#2B9FAD" @click="dc = true">Read more</span>
  <transition-expand>
<div v-if="dc">
  <br>
Transportation Sanitation & Disinfection – It is designed to sterilize your vehicles to fight the
spread of unwanted germs and diseases. Transportation vehicles are susceptible to
transmitting harmful viruses if not maintained properly due to the accumulation of outside
germs. With our thorough sanitization process, you can rest assured that you and your
vehicle are in good hands. 
<div v-if="width<768">
  <span style="color:#2B9FAD" v-if="dc" @click="dc = false">Read less</span>
</div>
</div>
  </transition-expand>
        </div>
      </div>
      <div>
   

      </div>
    </div>
      <div id="id_sec">
       <router-view/>
    </div>
  </div>
   <div v-if="selectlan=='ARABIC'">
      
         <div class="bcontainer">
      <div class="pmy-15">
         <div class="page-head arabic-font" dir="rtl">
                
                مكافحة انتشار الأمراض 
              </div>
        <div class="sub-content arabic-font  mt-2 " dir="rtl">
          تشمل خدمات شركة بليتش® لمكافحة انتشار الأمراض تعقيم وتطهير المنشآت مثل المساحات التجارية والسكنية. نساعد بشكل كبير في الحدّ من وجود البكتيريا الضارة ومنع انتقال الأمراض المعدية. إن ضمان الصحة والسلامة والرفاهية لعملائنا هي من أهم أولوياتنا. نقدم نوعين من خدمات مكافحة انتشار الأمراض:
<span v-if="!dc" style="color:#2B9FAD" @click="dc = true">المزيد</span>
  <transition-expand>
<div v-if="dc">
  <br><br>
  تطهير وتعقيم الأسطح - توفر هذه الخدمة حلول تنظيف صديقة للبيئة للقضاء على البكتيريا والفيروسات تمامًا من الهواء وجميع الأسطح. نستخدم أدوات متخصصة لتعقيم الأماكن ذات الزدحام الشديد مثل قاعات الاجتماعات والحمامات والمطابخ والمكاتب ومناطق الاستقبال، من بين أمور أخرى.
<br><br>
تعقيم وتطهير وسائل النقل – صممت هذه الخدمة لتعقيم مركباتك ومنع انتشار الجراثيم والأمراض غير المرغوب فيها. مركبات النقل عرضة لانتقال الفيروسات وتراكم الجراثيم الضارة إذا لم تعقم بشكل صحيح. ومن خلال عملية التعقيم الشاملة، يمكنك أن تطمئن إلى أنك وسيارتك في أيد أمينة.
<div v-if="width<768">
  <span style="color:#2B9FAD" v-if="dc" @click="dc = false">المختصر</span>
</div>
</div>

  </transition-expand>
        </div>
      </div>
      <div>
   

      </div>
    </div>
     <div id="id_sec">
       <router-view dir="rtl"/>
      </div>
  </div>
 </div>
</template>
<script>
//import pageheading from '../../../components/pageheading.vue'
export default {
  mounted() {
     
    this.width = screen.width;
    if(this.width<768){
      
          this.dc = false
        }else{
         if(localStorage.getItem('bleach-dc')){
              localStorage.removeItem('bleach-dc')
            }else{
              this.$nextTick(()=> window.document.getElementById("id_sec").scrollIntoView());
            }
        }
  },
    components:{},
     data(){
        return{
          dc:true,
          width:null,
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
       isCar(){
      if(this.width != null){
        if(this.width<768){
          return true
        }
        return false
      }
      return false
    },
    }

}
</script>
<style>

</style>