<template>
<div>
    <div v-if="selectlan =='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                  <ul class="show-head service-ui">
                     <li @click="$router.push('industrial')" >
                         <span class="dot-li"></span>
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')">
                          <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li class="service-ui-active"  >
                        <div class="dot-li-active"></div>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')" >
                         <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')" >
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')" >
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'HEALTH SECTOR CARE'"/>
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                   <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/infectioncontrol/sanitisation&disinfection')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Sanitization & Disinfication
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">HEALTH  SECTOR<span class="w400"> CARE</span></div>
                   <div class="sub-content mt-2">
                       Hale and hearty healthcare units are of foremost priority. We at BLEACH® understand the
stress and responsibilities these units have on a daily basis.
<br><br>
We clean and disinfect any size room with our hospital-strength disinfectant and superior
cleaning equipment. We work closely with healthcare institutions including medical centers,
clinics, hospitals, daycare, dental centers, etc. to ensure we provide the very highest
standards of hygiene.
                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    According to the U.S. Centers for Disease Control and Prevention, it’s reported that 1.7
million infections occur every year in hospitals! That’s tragic.

                 </div>
                  <div class="bold-heading mt-10">
                     WHY HEALTHCARE UNITS NEED PROFESSIONAL CARE ?
                 </div>
                 <div class="mt-5 sub-content">
                     Firstly, it reduces the chances of Hospital Acquired infections (HAIs). Secondly, a
professional cleaning and disinfection service to a healthcare setup is as vital as giving the 
right treatment to the patients, and we at BLEACH® specialize in maintaining such complex
structures and needs of the healthcare facilities.
                    
                 </div>
             </div>
         </div>
      </div>
  </div>
</div>
 <div v-if="selectlan =='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                  <ul class="show-head service-ui-ar">
                     <li @click="$router.push('industrial')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                       صيانة القطاع الصناعي 
                    </li>
                    <li @click="$router.push('corporate')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li @click="$router.push('education')" class="arabic-font">
                          <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li class="service-ui-active arabic-font"  >
                        <div class="dot-li-ar-active"></div>
                        الإهتمام بقطاع الرعاية الصحية
                    </li>
                    <li @click="$router.push('retail')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li @click="$router.push('sports')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        رياضة ولياقة بدنية
                    </li>
                    <li @click="$router.push('foods')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li class="arabic-font" @click="$router.push('hotels')" >
                         <span class="dot-li-ar"></span>
                        قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'اإلهتمام بقطاع الرعاية الصحية'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">الإهتمام بقطاع الرعاية الصحية </div>
                   <div class="sub-content mt-2 arabic-font">
                      وحدات الرعاية الصحية ذات أولوية قصوى. نحن في بليتش® نتفهم الضغط والمسؤولية التي تتحملها هذه الوحدات كل يوم بلا إنقطاع. 
<br><br>
نقوم بتنظيف وتعقيم أي غرفة مهما تنوع حجمها مع مظهر ذو قوة صناقية تناسب تلك المرافق ومعدات تنظيف
فائقة األداء. نحن نعمل عن كثب مع مؤسسات الرعاية الصحية بما في ذلك المراكز الطبية، والعيادات،
والمستشفيات، ومراكز الرعاية النهارية، ومراكز طب األسنان، إلخ .. لضمان تقديم أعلى معايير النظافة.
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                    هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                  وفقا لإفادة من المركز الأمريكي لمكافحة الأمراض والوقاية منها، فأن 1.7 مليون إصابة تسجل كل عام في المستشفيات!
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                  لماذا تحتاج وحدات الرعاية الصحية الى رعاية من جهات محترفة 
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                  أولاً، للتقليل من فرص الإصابة بالعدوى المكتسبة من المستشفيات. ثانيًا، تعتبر خدمة التنظيف والتطهير المهنية لمرفق الرعاية الصحية أمرًا حيويًا مثل تقديم العلاج المناسب للمرضى، ونحن في بليتش® متخصصون في صيانة مثل هذه الهياكل المعقدة تلبية لاحتياجات مرافق الرعاية الصحية. 
                    
                 </div>
             </div>
         </div>
      </div>
  </div>
</div>

</div>
</template>


<script>
import commenu from '../../components/menu/commenu.vue'

export default {
  components: { commenu },
  data(){
        return{
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
     }
}
</script>

<style>

</style>