<template>
<div>
    <div v-if="selectlan == 'EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                   <ul class="service-ui show-head">
                     <li @click="$router.push('industrial')" >
                         <span class="dot-li"></span>  
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')">
                          <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')"  >
                        <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')">
                       <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li class="service-ui-active"  >
                         <div class="dot-li-active"></div>  
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')" >
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'SPORTS & FITNESS FACILITIES MAINTENANCE'"/>
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                   <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/infectioncontrol/sanitisation&disinfection')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Sanitization & Disinfication
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">SPORTS & FITNESS <span class="w400">FACILITIES MAINTENANCE</span></div>
                   <div class="sub-content mt-2">
                       Sports & fitness centers have exceptional cleaning needs. This setup is amongst the most
impacted businesses in the times of COVID-19. At BLEACH®, we have trained professionals
that use advanced technology and cleaning solutions to keep your sports facilities healthy
and clean. Our services include cleaning and disinfecting high-touch areas, equipment, free
weights, showers, locker rooms, and the facility itself. Using state-of-the-art equipment and
cleaning solutions, we are able to ensure that our clients' facilities are kept clean, bacteriafree, and odor-free.
                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                   According to a survey conducted by IHRSA, customer satisfaction ratings fell from 83% to
43% if a gym is considered unclean, and retention rates fell from 90% to 52%. Further,
COVID has made people refrain from entering gyms due to the high rate of getting
infected.
                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS 
                 </div>
                 <div class="">
                     <div class="mt-5 sub-content">
                         BLEACH® provides you with an opportunity to welcome your clients again and have the new
ones with our trusted and certified cleaning & sanitization services. We further ensure:

                 <ul class="content-ui mt-3">
                            <li>Reduced risk of disease transmission </li>
                            <li> Control contaminants within the facility</li>

                        </ul>
                     </div>
                 </div>
             </div>
         </div>
      </div>
  </div>

</div>
  <div v-if="selectlan == 'ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                   <ul class="service-ui-ar show-head">
                     <li @click="$router.push('industrial')" class="arabic-font" >
                         <span class="dot-li-ar"></span>  
                       صيانة القطاع الصناعي 
                    </li>
                    <li @click="$router.push('corporate')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li @click="$router.push('education')" class="arabic-font">
                          <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li @click="$router.push('health')" class="arabic-font"  >
                        <span class="dot-li-ar"></span>
                       الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li @click="$router.push('retail')" class="arabic-font">
                       <span class="dot-li-ar"></span>
                        خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li class="service-ui-active arabic-font"  >
                         <div class="dot-li-ar-active"></div>  
                        رياضة ولياقة بدنية
                    </li>
                    <li @click="$router.push('foods')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li @click="$router.push('hotels')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'صيانة مرافق ممارسة األلعاب الرياضية والبدنية'"/>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">صيانة مرافق ممارسة األلعاب <span class="w400">الرياضية والبدنية   </span></div>
                   <div class="sub-content mt-2 arabic-font">
                       لمراكز ممارسة الألعاب الرياضية والبدنية احتياجات تنظيف استثنائية، فهذه المرافق كانت من بين الأكثر تأثراً أثناء الجائحة الصحية التي المت بالعالم، ولدينا في بليتش® محترفون مدربون على استخدام التكنولوجيا المتقدمة وحلول التنظيف التي تحافظ على صحة ونظافة مرافقكم الرياضية. 

  
<br><br>
تشمل خدماتنا تنظيف وتعقيم المناطق التي تتعرض للمس بشكل مستمر، والمعدات، والأوزان الحرة، ومرافق الاستحمام، وغرف تبديل الملابس، والمبنى الحاضن للمرفق الرياضي نفسه. باستخدام أحدث المعدات وحلول التنظيف، يمكننا التأكيد أن مرافق عملائنا نظيفة وخالية من كل البكتيريا الروائح الكريهة. 
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟ 
                 </div>
                 <div class="mt-3 sub-content arabic-font">
    وفقاً لاستطلاع أجرته الرابطة الدولية للصحة وكرة المضرب والأندية الرياضية IHRSA – وهو المظلة العالمية لأندية الصحة واللياقة البدنية – فإن معدلات رضا العملاء انخفضت من 83% الى 43% إذا اعتبرت صالة الرياضة البدنية غير نظيفة، كما تنخفض معدلات الاحتفاظ بالعملاء من 90% الى 52%، ويزيد على ذلك أن الجائحة تسببت في توقف الأفراد عن زيارة صالات ممارسة الالعاب الرياضية والبدنية بسبب المعدلات العالية للإصابة بالمرض. 
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     فوائد تنظيف مرافق ممارسة الألعاب الرياضية والبدنية 
                 </div>
                 <div class="">
                     <div class="mt-5 sub-content arabic-font">
                        فوائد تنظيف مرافق ممارسة الألعاب الرياضية والبدنية 

توفر لكم بليتش® فرصة للترحيب بعملائكم مرة أخرى، والحصول على عملاء جدد، من خلال خدمات التنظيف والتعقيم الموثوقة والمعتمدة لدينا، فنحن نؤكد لكم: 
     o                   <br><br>

                 <ul class="content-ui pr-5 mt-3 arabic-font">
                            <li>تقليل مخاطر انتقال المرض </li>
                            <li>     السيطرة على الملوثات داخل المنشأة </li>

                        </ul>
                     </div>
                 </div>
             </div>
         </div>
      </div>
  </div>

</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'
export default {
    components:{
        commenu,
    },
  data(){
        return{
                
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
     }
}
</script>

<style>

</style>