<template>
<div>
    <div v-if="selectlan=='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                  <ul class="show-head service-ui">
                     <li @click="$router.push('industrial')" >
                         <span class="dot-li"></span>
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')">
                          <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')"  >
                        <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')">
                       <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')">
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li class="service-ui-active"  >
                         <div class="dot-li-active"></div>  
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'FOOD & BEVERAGES FACILITY MAINTENANCE'" />
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                     <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2" >  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/kitchencleaning/kitchencleaning')">
                        <img src="../../assets/icons/recommend/kit.png" alt="" class="ml-2 mr-2">  Kitchen Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/infectioncontrol/sanitisation&disinfection')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Sanitization & Disinfication
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">FOOD & BEVERAGES <span class="w400">FACILITY MAINTENANCE</span></div>
                   <div class="sub-content mt-2">
                       Dirty kitchens are breeding grounds for bacteria that can lead to food poisoning. At
BLEACH®, it is our top priority to keep your kitchen, food preparation, food storage, and
dining areas clean and sanitary. We use eco-friendly, food-grade chemicals for all of your
restaurant cleaning needs. We can handle any of your restaurant cleaning needs with
professionalism and utmost care with our highly experienced staff and high-grade cleaning
equipment.
                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    According to the World Health Organization (WHO), one in 10 people fall ill after consuming
contaminated food, and 420,000 die every year, resulting in a loss of 33 million healthy lives
per year. Contaminated items cause more than 200 diseases, ranging from diarrhea to
cancers.

                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS 
                 </div>
                 <div class="">
                     <div class="mt-5 sub-content">
                         A clean and sanitary food facility boosts customers’ trust and gives them the confidence to
return. This adds up to the brand image, keeps people in and around the facility healthy,
and speaks volumes of the hygiene that you assure your customers of. Further:

                      <ul class="content-ui mt-3">
                            <li>A safe, healthy workplace </li>
                            <li>Positive, professional appearance</li>
                            <li>Few chances of cross-contaminatione</li>
                            <li>Asset prevention</li>
                        </ul>
                     </div>
                   
                 </div>
             </div>
         </div>
      </div>
  </div>

</div>
   <div v-if="selectlan=='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                  <ul class="show-head service-ui-ar">
                     <li @click="$router.push('industrial')" class="arabic-font">
                         <span class="dot-li-ar"></span>
                     صيانة القطاع الصناعي 
                    </li>
                    <li @click="$router.push('corporate')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li @click="$router.push('education')" class="arabic-font">
                          <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li @click="$router.push('health')" class="arabic-font" >
                        <span class="dot-li-ar"></span>
                        الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li @click="$router.push('retail')" class="arabic-font">
                       <span class="dot-li-ar"></span>
                       خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li @click="$router.push('sports')" class="arabic-font">
                         <span class="dot-li-ar"></span>
                        رياضة ولياقة بدنية
                    </li>
                    <li class="service-ui-active arabic-font"  >
                         <div class="dot-li-ar-active"></div>  
                       صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li @click="$router.push('hotels')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'صيانة مرافق التغذية والمرطبات'" />
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">صيانة مرافق التغذية <span class="w400">والمرطبات</span></div>
                   <div class="sub-content mt-2 arabic-font">
             تعد المطابخ المتسخة بيئة خصبة للبكتيريا التي يمكن أن تؤدي إلى التسمم الغذائي. من أهم أولوياتنا في بليتش® الحفاظ على نظافة وطهارة المطبخ، ومواقع إعداد الطعام وتخزينه، وأماكن تناول الطعام. لجميع احتياجات تنظيف مطعمكم نقوم باستخدام مواد كيميائية صديقة للبيئة ومناسبة للطعام. يمكننا التعامل مع أي من احتياجات تنظيف مطعمكم باحترافية وعناية قصوى من خلال طاقمنا ذي الخبرة العالية ومعدات التنظيف عالية الجودة المتوفرة لدينا. 
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                    هل تعلم؟ 
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                     طبقاً لما ورد من منظمة الصحة العالميةWHO ، فإن واحد من بين كل عشر اشخاص يصابون بنوع من المرض بعد تناول طعام ملوث، ويموت اربعمائة وعشرون ألفًا كل عام، مما يؤدي إلى فقدان ثلاثة وثلاثون مليون حياة صحية سنويًا. تسبب المواد الملوثة أكثر من مائتي مرض تتراوح ما بين الإسهال لتصل الى أنواع مختلفة من امراض السرطان. 
                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     فوائد تنظيف المطابخ ذات الطاقة الانتاجية التجارية 
                 </div>
                 <div class="">
                     <div class="mt-5 sub-content arabic-font">
                    يعزز مرفق الطعام النظيف والمعقم ثقة العملاء ويمنحهم السند للعودة، كما يضيف إلى تحسين صورة العلامة التجارية، ويحافظ على صحة الأشخاص داخل المنشأة وحولها، ويتحدث عن قدر كبير من النظافة التي تطمئن عملاءك عليها، ذلك بالإضافة إلى توفير: 
<br><br>
                      <ul class="pr-5 content-ui mt-3">
                            <li class="arabic-font">مكان عمل آمن وصحي </li>
                            <li class="arabic-font">المظهر المهني الإيجابي </li>
                            <li class="arabic-font">فرص قليلة لانتشار التلوث </li>
                            <li class="arabic-font">منع الأصول من التعرض للتلوث </li>
                        </ul>
                     </div>
                   
                 </div>
             </div>
         </div>
      </div>
  </div>
</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'
export default {
    components:{
        commenu,
    },
  data(){
        return{
                
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
     }
}
</script>


<style>

</style>