<template>
<div>
    <div v-if="selectlan=='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                   <ul class="service-ui show-head">
                     <li @click="$router.push('industrial')" >
                         <span class="dot-li"></span>
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')">
                          <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')"  >
                        <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li @click="$router.push('retail')">
                       <span class="dot-li"></span>
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')">
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')">
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li class="service-ui-active">
                         <div class="dot-li-active"></div>  
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'HOTEL & LUXURY SECTOR'" />
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3 " @click="goto('/service/specialcare/mattresscleaning')">
                        <img src="../../assets/icons/recommend/matress.png" alt="" class="ml-2 mr-2">  Mattress Cleaning
                    </div>
                     <div class="rec-item-name mt-3" @click="goto('/service/infectioncontrol/sanitisation&disinfection')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Sanitization & Disinfication
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">HOTEL & LUXURY <span class="w400">SECTOR</span></div>
                   <div class="sub-content mt-2">
                  Hotels are ranked based on the facility levels offered to their customers as well as the
hygiene and safety standards they maintain. BLEACH® has emerged as a premier hotelcleaning service provider with skilled personnel. We specialize in maintaining high standards
of cleanliness and sanitary in the hotel industry. Our cleaners are equipped with high-grade
tools that we use to clean your valuable assets such as sofas, carpets, and upholstery
without damaging them or compromising their durability or appearance. This has made us a
major player in the field of upholstery cleaning since we are able to provide some of the
most thorough and reliable services.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    70% of hotel guests rank cleanliness and hygiene as the most important factors when
choosing a hotel, and in making recommendations to friends, family and colleagues. Also,
90% of travelers avoid booking hotels described as being ‘dirty’.

                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS 
                 </div>
                 <div class="">
                     <div class="mt-5 sub-content">
                         BLEACH® gives you the edge with its impeccable and CIMS™ certified cleaning services.

                         <ul class="content-ui mt-3">
                            <li>Increased customer satisfaction</li>
                            <li>Improved rating and inflated bookings </li>
                            <li>Preferable choice of travelers</li>

                        </ul>
                     </div>

                 </div>
             </div>
         </div>
      </div>
  </div>
</div>
 <div v-if="selectlan=='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                   <ul class="service-ui-ar show-head">
                     <li @click="$router.push('industrial')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                      صيانة القطاع الصناعي 
                    </li>
                    <li @click="$router.push('corporate')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li @click="$router.push('education')" class="arabic-font">
                          <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li @click="$router.push('health')" class="arabic-font" >
                        <span class="dot-li-ar"></span>
                        الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li @click="$router.push('retail')" class="arabic-font">
                       <span class="dot-li-ar"></span>
                       خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li @click="$router.push('sports')" class="arabic-font">
                         <span class="dot-li-ar"></span>
                       رياضة ولياقة بدنية
                    </li>
                    <li @click="$router.push('foods')" class="arabic-font">
                         <span class="dot-li-ar"></span>
                        صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li class="service-ui-active arabic-font">
                         <div class="dot-li-ar-active"></div>  
                        قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'قطاع الفنادق والرفاهية'" />
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head  arabic-font">قطاع الفنادق <span class="w400"> والرفاهية </span></div>
                   <div class="sub-content mt-2 arabic-font">
                 يتم تصنيف الفنادق بناءً على مستوى المرافق التي تقدمها لعملائها، فضلاً عن معايير النظافة والسلامة التي يحافظون عليها. وقد برزت بليتش® كمزود خدمة تنظيف الفنادق الرائدة بوجود موظفيها المهرة، فنحن متخصصون في الحفاظ على مستويات عالية من النظافة والتعقيم في مجال صناعة الفنادق. تم تجهيز عمال النظافة لدينا بأدوات عالية الجودة نستخدمها لتنظيف أصولك القيمة مثل الأرائك والسجاد والمفروشات دون الإضرار بها أو المساس بمتانتها أو مظهرها. لقد جعلنا ذلك لاعباً رئيسياً في مجال تنظيف المفروشات لأننا قادرون على تقديم بعض الخدمات الأكثر شمولاً وموثوقية.
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                   هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                    70٪ من النزلاء يعتبرون النظافة والتعقيم من أهم العوامل عند اختيار الفندق وتقديم التوصيات للأصدقاء والعائلة والزملاء، كما يتجنب 90٪ من المسافرين حجز الفنادق الموصوفة بالقذارة. 

                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     الفوائد  
                 </div>
                 <div class="">
                     <div class="mt-5 sub-content arabic-font">
                        تمنحكم بليتش® الأفضلية مع خدمات التنظيف المعتمدة من خلال المعايير الإدارية لصناعة التنظيف CIMS® التي لا تشوبها شائبة. 

                         <ul class="content-ui mt-3 pr-5">
                            <li class="arabic-font">زيادة في معدلات رضا العملاء</li>
                            <li class="arabic-font">تصنيفات أعلى وحجوزات أكثر </li>
                            <li class="arabic-font">الاختيار المفضل للمسافرين</li>

                        </ul>
                     </div>

                 </div>
             </div>
         </div>
      </div>
  </div>
 
</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'
export default {
    components:{
        commenu,
    },
  data(){
        return{
                
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
     }
}
</script>

<style>

</style>