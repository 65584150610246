<template>
 <div>
    <div v-if="selectlan == 'EN'">
    <div class="">
      <carousel @changed="changed" items="1" :slideTransition="'fadeUp'" :autoplayTimeout='7000' :autoplay="true" :loop='true' :dots="false" :nav="false">
        <div class="main-slider-item-1">
          <div class="slider-inner">
            <h1 class="slider-main-text animate__animated animate__fadeInUp">
             Your Cleaning   <br />Search <br />
              <span class="w500">Stops Here</span>
            </h1>
            <div class="slider-text m-mt-20 animate__animated animate__fadeInUp animate__delay-08s">
              Expert Cleaning Solutions Making Spaces Germ & Dirt-free 
            </div>
            <button class="book-btn btn-trans m-mt-20 animate__animated animate__fadeInUp animate__delay-1s">Book Appointment Now</button>
          </div>
        </div>
        <div class="main-slider-item-2">
          <div class="slider-inner">
            <h1 class="slider-main-text">
              Trusted Cleaning <br />Partner  <br />
              <span class="w500"> Since 2013</span>
            </h1>
            <div class="slider-text">
             Certified Cleaning Services for Corporate Houses, Stores, Governates & More 
            </div>
            <button class="book-btn btn-trans">Book Appointment Now</button>
          </div>
        </div>
          <div class="main-slider-item-2">
          <div class="slider-inner">
            <h1 class="slider-main-text">
              Precision. <br />Perfection.  <br />
              <span class="w500">Bleach<sup style="font-size:40px">®</sup>.</span>
            </h1>
            <div class="slider-text">
             It's more than clean. It's an experience! 
            </div>
            <button class="book-btn btn-trans">Book Appointment Now</button>
          </div>
        </div>
         <div class="main-slider-item-2">
          <div class="slider-inner">
            <h1 class="slider-main-text">
              Environment First <br />Cleaning   <br />
              <span class="w500">Concept</span>
            </h1>
            <div class="slider-text">
             Our Carefully Chosen Products Keep Environmental Safety in Check 
            </div>
            <button class="book-btn btn-trans">Book Appointment Now</button>
          </div>
        </div>
      </carousel>
    </div>
    <section class="container" data-aos="fade-up">
      <div class="py-15">
        <div class="text-center section-heading" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" > 
          WHAT <span class="w500"> WE DO</span>
        </div>
        <div class="my-10 about-section" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
         Bleach® is a technology-driven cleaning company that offers high-end services for the most discerning clients. We deliver exceptional value through its 3-tier cleaning model, featuring uncompromising VIP services, flexible scheduling, and an uber-easy booking experience. 
        </div>
        <div class="service-margin" style="margin-top: 6%">
          <div class="row my-10" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000"> 
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/detailedcleaning')">
              <div class="d-flex">
                <div class="serive-line line-hide">&nbsp;</div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    style="margin-top:10px"
                      src="../../assets/icons/services/dc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    DETAILED <br />
                    CLEANING
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/specialcare')">
              <div class="d-flex">
                <div class="serive-line">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                      src="../../assets/images/services/special.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    SPECIAL  <br />
                    CARE
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/kitchencleaning')">
              <div class="d-flex">
                <div class="serive-line">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img

                      src="../../assets/icons/services/kc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    KITCHEN <br />
                    CLEANING
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/infectioncontrol')">
              <div class="d-flex">
                <div class="serive-line">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                    style="margin-top:10px"
                      src="../../assets/icons/services/ic.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                     INFECTION  <br />
                    CONTROL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
        data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000"
          class="section-heading text-center"
          style="
            font-style: normal;
            font-weight: 500;
            margin-top: 10%;
            margin-bottom: 3%;
          "
        >
          Clean, Protect and Freshen Your Premises
          <button class="book-btn" style="padding: 1px 20px 1px 20px">
            Book Your Service
          </button>
        </div>
      </div>
    </section>

    <section class="gray-bg" >
      <div class="container" data-aos="fade-up">
        <div class="py-15">
          <div class="text-center section-heading" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" >
            WHAT <span class="w500"> WE SERVE</span>
          </div>
          <div class="my-10 about-section" data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000">
           We pride ourselves on providing professional and quality cleaning services to residential and commercial clients. Our wide range of services allows you to customize a plan that fits your needs – Daily, Weekly, Monthly, or Event Clean-up. Subscribe from our full range of services. 
          </div>
          <div class="dflex-card" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
            <div class="card-div h-100" style="cursor: pointer;" @click="goto('residential')">
              <div class="who-serve-card-img">
                <img
                  src="../../assets/images/res.png"
                  class="text-center"
                  alt=""
                />
                <div class="who-text mt-3">Residential</div>
              </div>
              <div class="who-card-contents ">
                Custom solutions for domestic cleaning needs, ranging from Sanitization and Upholstery Cleaning to Facade Cleaning. 
                <br /><br /><br>
              </div>
            </div>
            <div style="margin: 20px" class="text-center">
              <div
                class="serive-line"
                style="
                  border-top: 2px solid #F1F3F5;
                  margin: 150% auto auto auto;
                "
              >
                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
              </div>
            </div>
            <div class="card-div h-100" style="cursor: pointer;" @click="goto('commercial')">
              <div class="who-serve-card-img">
                <img
                  src="../../assets/images/com.png"
                  class="text-center"
                  alt=""
                />
                <div class="who-text mt-3">Commercial</div>
              </div>
              <div class="who-card-contents">
               Extensive commercial cleaning services to the highest standards for Corporate Houses, Educational Facilities, Retail, Industrial requirements & more. 
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container" data-aos="fade-up">
        <div class="py-10">
          <div class="text-center section-heading" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" >
            WHAT <span class="w500"> MAKE US DIFFERENT</span>
          </div>
          <div
          data-aos="fade-up" data-aos-delay="500" data-aos-duration="1000" 
            class="my-10 about-section"
            style="padding-left: 20%; padding-right: 20%"
          >
            We follow the mantra of acknowledging customers’ needs first. Our globally-certified cleaning methods; professionally-trained cleaners ensuring services of international standards; and digitally-powered easy service booking experience depict our culture. 
          </div>
        </div>
      </div>
      <div class="pb-15 pt-5" style="padding: 0px 10% 0px 10%" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" >
       <div class="show-diff">
          <div class="row seven-cols ">
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(0)">
              <div class="diff-icons" :class="activeDiff.id==differece[0].id?'img-active-border':''">
                <img src="../../assets/images/makediff/ad.png"   alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[0].id?'active-text':''">Advanced Customercare</div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(1)">
              <div class="diff-icons" :class="activeDiff.id==differece[1].id?'img-active-border':''" >
                  <img src="../../assets/images/makediff/well.png" alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[1].id?'active-text':''">Well-Trained Professionals </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(2)">
              <div class="diff-icons" :class="activeDiff.id==differece[2].id?'img-active-border':''">
                  <img src="../../assets/images/makediff/eco.png"  alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[2].id?'active-text':''">Eco-Friendly Services  </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(3)">
              <div class="diff-icons" :class="activeDiff.id==differece[3].id?'img-active-border':''">
                <img src="../../assets/images/makediff/ch.png"  alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[3].id?'active-text':''">Child-Friendly Products  </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(4)">
              <div class="diff-icons" :class="activeDiff.id==differece[4].id?'img-active-border':''" >
                <img src="../../assets/images/makediff/pet.png" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[4].id?'active-text':''">Pet-Friendly Methods </div>
            </div>
          </div>
          <div class="col-md-4 mt-5 col-lg-2">
            <div class="text-center diff-card" @click="changeDiff(5)">
              <div class="diff-icons" :class="activeDiff.id==differece[5].id?'img-active-border':''" >
                <img src="../../assets/images/makediff/in.png" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[5].id?'active-text':''">Insurance Coverage</div>
            </div>
          </div>

        </div>
       </div>
       <div class="diff-side">
          <carousel
          data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
          
          items="1" 
            :autoplay="false"
            :dots="false"
            :nav="false"
            @dragged="changeItm"
          >
          <div class="text-center diff-card" @click="changeDiff(0)">
              <div class="text-center">
                <img src="../../assets/images/makediff/ad.png" class="ma"  style="width:auto" :class="activeDiff.id==differece[0].id?'img-active-border':'mmb-4'"  alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[0].id?'active-text':'mmt-4'">Advanced Customercare</div>
            </div>
             <div class="text-center diff-card" @click="changeDiff(1)">
              <div class="">
                  <img src="../../assets/images/makediff/well.png" class="ma"  style="width:auto" :class="activeDiff.id==differece[1].id?'img-active-border':'mmb-4'" alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[1].id?'active-text':'mmt-4'">Well-Trained Professionals </div>
            </div>
            <div class="text-center diff-card" @click="changeDiff(2)">
              <div class="">
                  <img src="../../assets/images/makediff/eco.png" class="ma"  style="width:auto" :class="activeDiff.id==differece[2].id?'img-active-border':'mmb-4'" alt="" />
              </div>
              <div class="diff-heading" :class="activeDiff.id==differece[2].id?'active-text':'mmt-4'">Eco-Friendly Services  </div>
            </div>
            <div class="text-center diff-card" @click="changeDiff(3)">
              <div class="">
                <img src="../../assets/images/makediff/eco.png" class="ma"  style="width:auto"  :class="activeDiff.id==differece[3].id?'img-active-border':'mmb-4'" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[3].id?'active-text':'mmt-4'">Child-Friendly Products  </div>
            </div>
             <div class="text-center diff-card" @click="changeDiff(4)">
              <div class="">
                <img src="../../assets/images/makediff/eco.png" class="ma"  style="width:auto" :class="activeDiff.id==differece[4].id?'img-active-border':'mmb-4'" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[4].id?'active-text':'mmt-4'">Pet-Friendly Methods </div>
            </div>
            <div class="text-center diff-card" @click="changeDiff(5)">
              <div class="">
                <img src="../../assets/images/makediff/eco.png"  class="ma"  style="width:auto"  :class="activeDiff.id==differece[5].id?'img-active-border':'mmb-4'" alt="" />
              </div>
              <div class="diff-heading"  :class="activeDiff.id==differece[5].id?'active-text':'mmt-4'">Insurance Coverage</div>
            </div>
          </carousel>
       </div>
      </div>
      <div class="container" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" >
        <div class="py-10">
          <div
            class="about-section"
            style="padding-left: 5%; padding-right: 5%"
          >
           <span style="color: #2B9FAD;">{{activeDiff.title}}: </span>{{activeDiff.content}}
          </div>
        </div>
      </div>
    </section>
    <section class="gray-bg">
      <div class="container" data-aos="fade-up">
        <div class="py-15">
          <div class="text-center section-heading">
             CORPORATIVE  <span class="w500"> FEEDBACK</span>
          </div>
        </div>
        <div class="py-10">
          <carousel
          data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
          :margin="10"
            :responsive="{
              0: { items: 1, nav: false },
              992: { items: 2, nav: false },
              1280: { items: 3, nav: false },
            }"
            :autoplay="true"
            :dots="false"
            :nav="false"
          >
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
          </carousel>
        </div>
         <div class="py-10" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
          <div class="text-center section-heading">
             CUSTOMER <span class="w500"> FEEDBACK</span>
          </div>
        </div>
        <div class="py-10">
          <carousel
          data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
          :margin="10"
            :responsive="{
              0: { items: 1, nav: false },
              992: { items: 2, nav: false },
              1280: { items: 3, nav: false },
            }"
            :autoplay="true"
            :dots="false"
            :nav="false"
          >
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
          </carousel>
        </div>
        <br><br>
      </div>
    </section>
    <section class="container">
      <div class="py-15" data-aos="fade-up" >
        <br><br>
        <div class="text-center section-heading" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
          OUR CERTIFICATIONS
        </div>
         <div
         data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000"
            class="my-10 about-section"
            style="padding-left: 20%; padding-right: 20%"
          >
           Bleach® pioneers in the movement towards greener cleaning products and services. We are certified by global organizations like ISSA, and ISO.
          </div>
          <div class="py-3 text-center" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1000" >
            <img src="../../assets/images/certificate/1.1.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.2.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.3.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.4.png" class="mx-1" alt="">

          </div>
          <div style="margin-top:7%" class="gray-head"  data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"> 
            WE <span class="w400">Work with the best partners</span>
          </div>
          <div class="container mt-6"  data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <div class="">
              <carousel items="1"  :autoplay="false" :loop='true' :dots="true" :nav="false">
              <div class="row px-15">
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/1.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/2.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/3.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/4.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/5.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/6.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/7.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/8.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/9.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/10.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/11.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/12.png" alt="" class="img-shadow">
            </div>
          </div>
            <div class="row px-15">
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/13.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/14.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/15.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/16.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/17.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/18.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/19.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/20.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/21.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/22.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/11.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/12.png" alt="" class="img-shadow">
            </div>
          </div>
              </carousel>
            </div>
          </div>
      </div>
      <br>
      <br>
    </section>
  </div>
   <div v-if="selectlan == 'ARABIC'" >
    <div class="">
      <carousel items="1" :autoplay="false"  :dots="false" :nav="false">
        <div class="main-slider-item-1">
          <div class="slider-inner text-right">
            <h1 class="slider-main-text">
             هنا ينتهي البحث  <br />  عن
             <br> <span class="w500">أعمال النظافة</span>
            </h1>
            <div class="slider-text m-mt-20">
             حلول أخصائيي التنظيف تجعل المكان خا ٍل من الجراثيم واألوساخ
            </div>
            <button class="book-btn m-mt-20">Book Appointment Now</button>
          </div>
        </div>
        <div class="main-slider-item-2">
          <div class="slider-inner text-right">
            <h1 class="slider-main-text">
              شريك تنظيف <br />موثوق به منذ  <br />
              <span class="w500"> عام 2013</span>
            </h1>
            <div class="slider-text">
            خدمات التنظيف معتمدة لمكاتب الشركات والمتاجر والمديريات والمزيد
            </div>
            <button class="book-btn">Book Appointment Now</button>
          </div>
        </div>
          <div class="main-slider-item-2">
          <div class="slider-inner text-right">
            <h1 class="slider-main-text">
              الدقة.<br />التفاصيل.  <br />
              <span class="w500"><sup>®</sup>. بليتش</span>
            </h1>
            <div class="slider-text">
            يتعدى األمر أن يكون من مجرد تنظيف عادية، إنها تجربة ال تنسى
            </div>
            <button class="book-btn">Book Appointment Now</button>
          </div>
        </div>
         <div class="main-slider-item-2">
          <div class="slider-inner text-right">
            <h1 class="slider-main-text">
              مفهوم التنظيف <br />الذي يراعي   <br />
              <span class="w500">البيئة أو اا</span>
            </h1>
            <div class="slider-text">
             تحرص منتجاتنا المختارة بعناية على ضمان سالمة البيئة
            </div>
            <button class="book-btn">Book Appointment Now</button>
          </div>
        </div>
      </carousel>
    </div>
    <div dir="rtl">
          <section class="container">
      <div class="py-15">
        <div class="text-center section-heading">
          خدماتنا 
          <!-- <span class="w500"> WE DO</span> -->
        </div>
        <div class="my-10 about-section">
          إن بليتش<sup>®</sup> شركة تنظيف تعتمد على التكنولوجيا تقدم خدمات راقية عالية الجودة للعمالء األكثر
تمي ازا. نقدم لعمالئنا قيمة استثنائية من خالل نموذج التنظيف ثالثي المستويات، الذي يتميز بأداء
فائق الجودة لعمالئنا من كبار الشخصيات، والمواعيد المرنة، وتجربة حجز سهلة توفر خيارات
رائعة
        </div>
        <div class="service-margin" style="margin-top: 6%">
          <div class="row my-10">
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/detailedcleaning')">
              <div class="d-flex">
                <div class="serive-line line-hide">&nbsp;</div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                     style="margin-top:10px"
                      src="../../assets/icons/services/dc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                     التنظيف الدقيق
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/specialcare')">
              <div class="d-flex">
                <div class="serive-line">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                      src="../../assets/images/services/special.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                   الرعاية الخاصة
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/kitchencleaning')">
              <div class="d-flex">
                <div class="serive-line">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                       src="../../assets/icons/services/kc.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                   تنظيف المطبخ 
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-10" @click="goto('service/infectioncontrol')">
              <div class="d-flex">
                <div class="serive-line">
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                </div>
                <div class="service-card">
                  <div class="service-card-circle">
                    <img
                     style="margin-top:10px"
                      src="../../assets/icons/services/ic.png"
                      alt=""
                    />
                  </div>
                  <div class="cleaning-card-name text-center">
                    مكافحة انتشار األمراض 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="section-heading text-center"
          style="
            font-style: normal;
            font-weight: 500;
            margin-top: 10%;
            margin-bottom: 3%;
          "
        >
          احرص على تنظيف وحماية وتجديد األجواء في مكان عملك 
          <button class="book-btn" style="padding: 1px 20px 1px 20px">
            احجز الخدمة اآلن
          </button>
        </div>
      </div>
    </section>


    <section class="gray-bg">
      <div class="container">
        <div class="py-15">
          <div class="text-center section-heading">
            عمالؤنا 
            <!-- <span class="w500"> WE SERVE</span> -->
          </div>
          <div class="my-10 about-section">
            نفخر بتقديم أفضل خدمات تنظيف االحترافية لعمالئنا في المنازل وأماكن العمل. تتيح لك مجموعتنا
الواسعة من الخدمات تخصيص خطة تناسب احتياجاتك – تنظيف أسبوعي أو شهري أو التنظيف
بعد المناسبات. اشترك لتصلك نشرة بمجموعة خدماتنا الكاملة.
          </div>
          <div class="dflex-card">
            <div class="card-div h-100" style="cursor: pointer;" @click="goto('residential')">
              <div class="who-serve-card-img">
                <img
                style="margin-left:0px;margin-right:40%"

                  src="../../assets/images/res.png"
                  class="text-center"
                  alt=""
                />
                <div class="who-text mt-3">تنظيف الشقق السكنية</div>
              </div>
              <div class="who-card-contents ">
               حلول مخصصة الحتياجات التنظيف المنزلية، بد اءا من التعقيم وتنظيف المفروشات إلى تنظيف
الواجهات.
                <br /><br /><br>
              </div>
            </div>
            <div style="margin: 20px" class="text-center">
              <div
                class="serive-line"
                style="
                  border-top: 2px solid #F1F3F5;
                  margin: 150% auto auto auto;
                "
              >
                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;
              </div>
            </div>
            <div class="card-div h-100" style="cursor: pointer;" @click="goto('commercial')">
              <div class="who-serve-card-img">
                <img
                style="margin-left:0px;margin-right:40%"
                  src="../../assets/images/com.png"
                  class="text-center"
                  alt=""
                />
                <div class="who-text mt-3">تنظيف أماكن العمل</div>
              </div>
              <div class="who-card-contents">
               خدمات تنظيف أماكن عمل واسعة النطاق وفق أعلى المعايير تشمل تنظيف مقرات الشركات
والمرافق التعليمية والمتاجر والمرافق الصناعية وغيرها.
                <br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="py-10">
          <div class="text-center section-heading">
            ما الذي يميزنا؟ 
            <!-- <span class="w500"> MAKE US DIFFERENT</span> -->
          </div>
          <div
            class="my-10 about-section"
            style="padding-left: 20%; padding-right: 20%"
          >
           نتبع شعار . ونعكس ثقافتنا من خالل اعتماد طرق التنظيف
األحدث عالمياا؛ وعمال النظافة المدربين مهنياا على تقديم خدمات بمعايير دولية؛ وسهولة حجز
الخدمة رقمياا.
          </div>
        </div>
      </div>
      <div class="py-15" style="padding: 0px 10% 0px 10%">
        <div class="row seven-cols">
          <div class="col-md-2">
            <div class="text-center diff-card" @click="changeDiff(0)">
              <div class="">
                              <img src="../../assets/images/makediff/ad.png" :class="activeDiffAr.id==differeceAr[0].id?'img-active-border':'mmb-4'"  alt="" />

              </div>
              <div class="diff-heading" :class="activeDiffAr.id==differece[0].id?'active-text':'mmt-4'">رعاية العمالء المتقدمة</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-center diff-card" @click="changeDiff(1)">
              <div class="">
                             <img src="../../assets/images/makediff/well.png" :class="activeDiffAr.id==differeceAr[1].id?'img-active-border':'mmb-4'"  alt="" />

              </div>
              <div class="diff-heading" :class="activeDiffAr.id==differece[1].id?'active-text':'mmt-4'">محترفون مدربون تدريباً عالي</div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-center diff-card" @click="changeDiff(2)">
              <div class="">
                               <img src="../../assets/images/makediff/eco.png" :class="activeDiffAr.id==differeceAr[2].id?'img-active-border':'mmb-4'"  alt="" />

              </div>
              <div class="diff-heading" :class="activeDiffAr.id==differece[2].id?'active-text':'mmt-4'">خدمات صديقة للبيئة </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-center diff-card" @click="changeDiff(3)">
              <div class="">
                                             <img src="../../assets/images/makediff/eco.png" :class="activeDiffAr.id==differeceAr[3].id?'img-active-border':'mmb-4'"  alt="" />

              </div>
              <div class="diff-heading" :class="activeDiffAr.id==differece[3].id?'active-text':'mmt-4'">منتجات صديقة للطفولة  </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-center diff-card" @click="changeDiff(4)">
              <div class="">
                                              <img src="../../assets/images/makediff/eco.png" :class="activeDiffAr.id==differeceAr[4].id?'img-active-border':'mmb-4'"  alt="" />

              </div>
              <div class="diff-heading" :class="activeDiffAr.id==differece[4].id?'active-text':'mmt-4'">وسائل صديقة للحيوانات األليفة </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="text-center diff-card" @click="changeDiff(5)">
              <div class="">
                                             <img src="../../assets/images/makediff/eco.png" :class="activeDiffAr.id==differeceAr[5].id?'img-active-border':'mmb-4'"  alt="" />

              </div>
              <div class="diff-heading" :class="activeDiffAr.id==differece[5].id?'active-text':'mmt-4'">التغطية التأمينية للعمالء والموظفين  </div>
            </div>
            
          </div>

        </div>
      </div>
      <div class="container">
        <div class="py-10">
          <div
            class="about-section"
            style="padding-left: 5%; padding-right: 5%"
          >
          <span style="color: #2B9FAD;">{{activeDiffAr.title}}: </span> {{activeDiffAr.content}}
          </div>
        </div>
      </div>
    </section>
    <section class="gray-bg">
      <div class="container">
        <div class="py-15">
          <div class="text-center section-heading">
             CORPORATIVE  <span class="w500"> FEEDBACK</span>
          </div>
        </div>
        <div class="py-10">
          <carousel
          
          :margin="10"
            :responsive="{
              0: { items: 1, nav: false },
              992: { items: 2, nav: false },
              1280: { items: 3, nav: false },
            }"
            :autoplay="false"
            :dots="false"
            :nav="false"
          >
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div> <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div> <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div> <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div> <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div> <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
          </carousel>
        </div>
         <div class="py-10">
          <div class="text-center section-heading">
             CUSTOMER <span class="w500"> FEEDBACK</span>
          </div>
        </div>
        <div class="py-10">
          <carousel
          :margin="10"
            :responsive="{
              0: { items: 1, nav: false },
              992: { items: 2, nav: false },
              1280: { items: 3, nav: false },
            }"
            :autoplay="false"
            :dots="false"
            :nav="false"
          >
            <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
             <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
             <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
             <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
             <div>
              <div class="d-flex">
                <img
                  src="../../assets/images/person.png"
                  alt=""
                  class="review-person-img"
                />
                <div class="review-person">
                  <h4
                    class="review-person-name mr-3"
                    style="margin-bottom: 1px !important"
                  >
                    NAME
                  </h4>
                  <div class="service-content mr-3">Local Guide</div>
                </div>
              </div>
              <div class="d-flex mt-3">
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                 <img
                  src="../../assets/images/yellow.png"
                  alt=""
                  class="review-start"
                />
                <img
                  src="../../assets/images/normal.png"
                  alt=""
                  class="review-start"
                />
                <div class="review-time mr-3">2 Years Ago</div>
              </div>
              <div class="service-content mt-2">
                Amazing job, we hired them to clean our restaurant in Kuwait City Gyros.
              </div>
            </div>
   
          </carousel>
        </div>
        <br><br>
      </div>
    </section>
    <section class="container">
      <div class="py-15">
        <br><br>
        <div class="text-center section-heading">
          شهاداتنا
        </div>
         <div
            class="my-10 about-section"
            style="padding-left: 20%; padding-right: 20%"
          >
          شركة بليتشR رائدة في سعيها نحو العمل بمنتجات وخدمات تنظيف أكثر مراعاة للبيئة. لذلك نحن
معتمدون من قبل المنظمات العالمية مثل ISSA و ISO .اعرف المزيد 
          </div>
          <div class="py-3 text-center">
            <img src="../../assets/images/certificate/1.1.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.2.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.3.png" class="mx-1" alt="">
            <img src="../../assets/images/certificate/1.4.png" class="mx-1" alt="">

          </div>
          <div style="margin-top:7%" class="gray-head">
            WE <span class="w400">Work with the best partners</span>
          </div>
          <div class="container mt-6">
            <div class="">
              <div class="row px-15">
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/1.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/2.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/3.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/4.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/5.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/6.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/7.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/8.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/9.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/10.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/11.png" alt="" class="img-shadow">
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 mt-4 text-center">
              <img src="../../assets/images/clients/12.png" alt="" class="img-shadow">
            </div>
          </div>
            </div>
          </div>
      </div>
      <br>
      <br>
    </section>
    </div>
  </div>
 </div>

</template>

<script>
import carousel from "vue-owl-carousel";
import $ from "jquery";
export default {
  mounted(){
    this.activeDiff = this.differece[0]
    this.activeDiffAr = this.differeceAr[0]
  },
  components: { carousel },
  data(){
    return{
      selectlan:localStorage.getItem('bleach-lang'),
      activeDiff:{},
      activeDiffAr:{},
      differece:[
        {
          id:1,
          title:"Advanced Customer Care ",
          content:"Our customer care service combines the best technology, training, and service to provide an excellent experience. With a team of dedicated professionals operating 24x7, every customer is addressed personally. Our dedicated personal helpdesk agent ensures the smooth execution of their project. Connect with us anytime on WhatsApp. "
        },
         {
          id:2,
          title:"Well-Trained Professionals ",
          content:"We take pride in calling our cleaners ‘Professionals’. The applied training methods to our crew members at BLEACH® are compliant with ISSA®’s CIMS™ — the world's highest recognized certification in the industry of cleaning and hospitality. Their annual training curriculum covers hands-on training on team management, time management, advanced cleaning and infection control applications, risk management, and an overview of the latest equipment, materials, and supplies. "
        },
        {
          id:3,
          title:'Eco-Friendly Services ',
          content:'BLEACH® takes pride in its cleaning abilities and turns that over to the hands of its clients by helping to keep the planet clean. We implement efficient cleaning practices to reduce waste and chemical use, use non-toxic, environmentally friendly products, and promote a healthy environment. '
        },
        {
          id:4,
          title:'Child-Friendly Products ',
          content:'In order to avoid the health risks and hazards of conventional cleaning products to children, we use only all-natural, non-toxic cleaning products. Our cleaning chemicals (Green Seal/EPA certified) are free of harmful chemicals which could poison, irritate or harm your child. Our cleaning chemicals are free from bleach, phthalates, phosphates, petroleum solvents & triclosan (we only use it in special cases and after the client’s approval). '
        },
        {
          id:5,
          title:'Pet-Friendly Methods ',
          content:'BLEACH® loves pets as much as you do. We adhere to best cleaning practices at home or facility. Our cleaning products are largely pet-friendly, containing minimal or no ammonia or bleach since they are toxic to them. Our products are safe around and do not irritate them with allergies or sensitivities.   '
        },
        {
          id:6,
          title:'Lnsurance Coverage for Customers & Staff ',
          content:'At BLEACH®, we value our customers and staff equally. We provide insurance coverage to clients’ assets, safeguarding them from any mishap and accidental damages at the time of our service visit.'
        }
      ],
      differeceAr:[
        {
          id:1,
          title:"رعاية العمالء المتقدمة",
          content:"تجمع خدمة رعاية العمالء لدينا بين أفضل التقنيات والتدريبات والخدمات لمنح العميل تجربةممتازة. بوجود فريق من المحترفين المتخصصين الذين يعملون على مدار الساعة طوال أياما معاملةا ضابط خدمة العمالء األسبوع، نمنح كل عميل من عمالئن خاصة في شركتنا بليتش.. يضمنّي وقت عبر الواتساب.التنفيذ السلس للخدمة التي يطلبها عميلنا بكل إخالص. تواصل معنا في "
        },
         {
          id:2,
          title:"محترفون مدربون تدريباً عاليا",
          content:"نفخر بإطالق اسم "+"المحترفين"+" على عمال النظافة لدينا. تتوافق أساليب التدريب المطبقة على أفرادطاقمنا في بليتشR مع معايير CIMS s®'ISSA - ™وهي أعلى الشهادة معترف بها في العالمفي مجال التنظيف والضيافة. يغطي منهج التدريب السنوي التدريب العملي على إدارة الفريق و إدارةالوقت والتطبيقات المتقدمة للتنظيف ومكافحة انتشار األمراض وإدارة المخاطر، ويطلعون علىأحدث المعدات والمواد واإلمدادات ضمن هذا المجال. "
        },
        {
          id:3,
          title:'خدمات صديقة للبيئة',
          content:'تفخر بليتشR بما لديها من قدرات على التنظيف تمنحها لعمالئها مع ضمان الحفاظ على نظافةالكوكب. إننا نطبق ممارسات تنظيف فعالة للح ّد من النفايات واستخدام المواد الكيميائية، واستخدامالمنتجات غير سامة وصديقة البيئة، وتعزيز البيئة الصحية.'
        },
        {
          id:4,
          title:'منتجات صديقة للطفولة',
          content:'من أجل تجنب المخاطر الصحية ومخاطر منتجات التنظيف التقليدية على األطفال، فإننا نستخدمفقط منتجات التنظيف الطبيعية غير السامة. مواد التنظيف الكيميائية المعتمدة لدينا )معتمدة منمنظمة غرين سيل/ وكالة حماية البيئة( خالية من المواد الكيميائية الضارة التي يمكن أن تسمم الطفلأو تسبب له التهيج أو األذى. مواد التنظيف الكيميائية المعتمدة لدينا خالية من المبيضات والفثاالتوالفوسفات ومذيبات البترول والتريكلوسان )نستخدمها فقط في حاالت خاصة وبعد موافقة العميل(.'
        },
        {
          id:5,
          title:'وسائل صديقة للحيوانات األليفة',
          content:'تحب بليتشR الحيوانات األليفةوتسعى لضمان سالمتها مثلكم تماما. ونحن ملتزمون باعتماد أفضل اممارسات التنظيف في المنزل أو المنشأة. منتجات التنظيف المعتمدة لدينا مناسبة إلى ح ّد كبيرللحيوانات األليفة، وتحتوي على الح ّد األدنى أو خالية من األمونيا أو المبيض ألنها قد تسبب التسممبسبب المواد المثيرة لالهتياج أو الحساسية.لهم. منتجاتنا آمنة للمحيط وال تسبب تهيجا'
        },
        {
          id:6,
          title:'التغطية التأمينية للعمالء والموظفين',
          content:'نحن نقدر عمالءنا وموظفينا بالقد نفسه في شركة بليتش R. نقدم تغطية تأمينية ألصول العمالء،ونحميهم من وقوع أي حوادث أو أضرار عرضية أثناء زيارتنا لتقديم خدماتنا لهم.كما أن جميع الفنيين العاملين لدى بليتش مشمولون بالتأمين على الحياة. باإلضافة إلى ذلك، نحتفظبسجل خدمة لتحسين جودة خدماتنا لمنحك رضا بنسبة 100 ٪ في الزيارة التالية. هذا يعني أنه معبليتشR ال توجد مخاطر على عمالئنا الذين يضمنون دائ اما أفضل الخدمات.'
        }
      ]
    }
  },
  methods:{
    changeItm(event){
      
      this.activeDiff = this.differece[event.item.index]
    },
    goto(loc){
      window.location = loc
    },
    changeDiff(id){
      this.activeDiff = this.differece[id]
      this.activeDiffAr = this.differeceAr[id]
    },
    changed(){
                  $('.slider-main-text').addClass('animate__animated animate__fadeInUp').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
              $('.slider-main-text').removeClass('animate__animated animate__fadeInUp');
          });
          $('.slider-text').addClass('animate__animated animate__fadeInUp animate__delay-08s').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
            $('.slider-text').removeClass('animate__animated animate__fadeInUp animate__delay-08s');
        });
        $('.btn-trans').addClass('animate__animated animate__fadeInUp animate__delay-1s').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
          $('.btn-trans').removeClass('animate__animated animate__fadeInUp animate__delay-1s');
      });
    }

  }
};
</script>

<style></style>
