<template>
<div>
        <div v-if="selectlan=='EN'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                   <ul class="service-ui show-head">
                     <li @click="$router.push('industrial')" >
                         <span class="dot-li"></span>
                       Industrial Sector
                    </li>
                    <li @click="$router.push('corporate')" >
                         <span class="dot-li"></span>
                        Corporate Offices
                    </li>
                    <li @click="$router.push('education')">
                          <span class="dot-li"></span>
                        Educational Facilities
                    </li>
                     <li @click="$router.push('health')"  >
                        <span class="dot-li"></span>
                        Health Care Sector
                    </li>
                    <li class="service-ui-active"  >
                        <div class="dot-li-active"></div> 
                        Retail Sector
                    </li>
                    <li @click="$router.push('sports')" >
                         <span class="dot-li"></span>
                        Sports & Fitness
                    </li>
                    <li @click="$router.push('foods')" >
                         <span class="dot-li"></span>
                        Foods & Beverages
                    </li>
                     <li @click="$router.push('hotels')" >
                         <span class="dot-li"></span>
                        Hotels & Luxury
                    </li>
                 </ul>
                 <commenu :name="'RETAIL SECTOR CLEANING'" />
                  <div class="mt-5">
                    <div class="recommended-head">
                        RECOMMENDED SERVICES
                    </div>
                    <div class="rec-item-name mt-3"  @click="goto('/service/detailedcleaning/deepcleaning')">
                        <img src="../../assets/icons/recommend/deep.png" alt="" class="ml-2 mr-2">  Deep Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/detailedcleaning/generalcleaning')">
                        <img src="../../assets/icons/recommend/general.png" alt="" class="ml-2 mr-2">  General Cleaning
                    </div>
                    <div class="rec-item-name mt-3" @click="goto('/service/infectioncontrol/sanitisation&disinfection')">
                        <img src="../../assets/icons/recommend/inf.png" alt="" class="ml-2 mr-2">  Sanitization & Disinfication
                    </div>
                 </div>
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head">RETAIL SECTOR <span class="w400">CLEANING</span></div>
                   <div class="sub-content mt-2">
                   We have been working with all kinds and sizes of shops/stores, from independent retailers
to large chains and the most renowned brands in Kuwaiti Market. We are proud to work
with a wide range of clients from various fields. Our ability, dedication, and desire to exceed
expectations are why we have been successful in retaining contracts with our most valued
clients.

                 </div>
                 <div class="bold-heading mt-10">
                     DID YOU KNOW ?
                 </div>
                 <div class="mt-3 sub-content">
                    Customer retention is cleanliness — 32% of consumers have stuck up their noses at
businesses that appear dirty inside or out, and never return.

                 </div>
                  <div class="bold-heading mt-10">
                     BENEFITS 
                 </div>
                 <div class="mt-5 sub-content">
                     BLEACH® assures you that your business remains profitable with a high rate of footfall. We
are a trusted cleaning partner in many retail chains. Our highly skilled cleaners restore the
charm of your retail space that lures customers. BLEACH® staff uses advanced cleaning
systems and tools to clean and sanitize your store and make it ‘ready-to-visit’ for your
valuable and potential customers.
                   
                 </div>
             </div>
         </div>
      </div>
  </div>
</div>
    <div v-if="selectlan=='ARABIC'">
      <div class="gray-bg-2">
      <div class="bcontainer">
         <div class="row py-15">
             <div class="col-md-4 col-lg-3">
                   <ul class="service-ui-ar show-head">
                     <li @click="$router.push('industrial')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                       صيانة القطاع الصناعي 
                    </li>
                    <li @click="$router.push('corporate')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        مقرات ومكاتب الشركات والمؤسسات
                    </li>
                    <li @click="$router.push('education')" class="arabic-font">
                          <span class="dot-li-ar"></span>
                        صيانة المرافق التربوية والتعليمية 
                    </li>
                     <li @click="$router.push('health')"  class="arabic-font" >
                        <span class="dot-li-ar"></span>
                       الإهتمام بقطاع الرعاية الصحية 
                    </li>
                    <li class="service-ui-active arabic-font"  >
                        <div class="dot-li-ar-active"></div> 
                       خدمات النظافة لقطاع البيع بالتجزئة
                    </li>
                    <li @click="$router.push('sports')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                        رياضة ولياقة بدنية
                    </li>
                    <li @click="$router.push('foods')"  class="arabic-font">
                         <span class="dot-li-ar"></span>
                        صيانة مرافق التغذية والمرطبات 
                    </li>
                     <li @click="$router.push('hotels')" class="arabic-font" >
                         <span class="dot-li-ar"></span>
                       قطاع الفنادق والرفاهية
                    </li>
                 </ul>
                 <commenu :name="'خدمات النظافة لقطاع البيع بالتجزئة'" />
             </div>
             <div class="col-md-8 col-lg-9">
                 <div class="cleaning-head show-head arabic-font">خدمات النظافة <span class="w400">لقطاع البيع بالتجزئة</span></div>
                   <div class="sub-content mt-2 arabic-font">
                       لقد عملنا مع جميع أنواع وأحجام محلات ومتاجر البيع بالتجزئة، من التجار المستقلين إلى السلاسل الكبيرة وأشهر العلامات التجارية في السوق الكويتي. نفخر بالعمل مع مجموعة واسعة من العملاء من مختلف المجالات. قدرتنا وتفانينا ورغبتنا في تجاوز التوقعات هي سبب نجاحنا بالاحتفاظ بالعقود مع عملائنا الكرام. 
                 </div>
                 <div class="bold-heading mt-10 arabic-font">
                     هل تعلم؟
                 </div>
                 <div class="mt-3 sub-content arabic-font">
                  النظافة هي أحد أهم عوامل الإحتفاظ بالعملاء، فقد أدار 32% من المستهلكين ظهورهم للمتاجر أو الأعمال التي يظهر عليها علامات عدم النظافة سواء من الداخل او الخارج، ولم يعودوا لها قط. 

                 </div>
                  <div class="bold-heading mt-10 arabic-font">
                     فوائد نظافة محالت البيع بالتجزئة 
                 </div>
                 <div class="mt-5 sub-content arabic-font">
                     تؤكد بليتش® لكم أن أعمالكم ستظل مربحة مع ارتفاع معدل حركة المرور على الأقدام. نحن شريك تنظيف موثوق به لدى العديد من سلاسل البيع بالتجزئة. يعيد عمال النظافة ذوو المهارات العالية لدينا سحر مساحة البيع بالتجزئة التي تجذب العملاء. يستخدم موظفي بليتش® أنظمة وأدوات تنظيف متقدمة لتنظيف وتعقيم متجرك وجعله جاهزًا لزيارة عملائك الكرام والمحتملين. 
                   
                 </div>
             </div>
         </div>
      </div>
  </div>

</div>

</div>
</template>

<script>
import commenu from '../../components/menu/commenu.vue'
export default {
    components:{
        commenu,
    },
  data(){
        return{
                
            selectlan:localStorage.getItem('bleach-lang')
        }
    },
    methods:{
         goto(loc) {
         window.location = loc;
        },
     }
}
</script>

<style>

</style>